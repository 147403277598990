.mob__nav {
  display: none;
  z-index: 10;
  font-family: $font-family;
  &.active {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: none;
    }
    display: block;
    position: fixed;
    top: 0;
    padding-top: 85px;
    left: 0;
    right: 0;
    min-height: 100%;
    min-height: 100vh;
    background-color: $header__main__mob__nav--bg--color;
    overflow-y: scroll;
    ul {
      margin: 0;
      padding: 0 25px;
    }
    li {
      display: block;
      //height: 46px;
      width: 100%;
      ul {
        display: block;
        padding: 0 0 20px 0;
        overflow: hidden;
      }
      &.fb {
        display: none;
      }
    }
    a {
      display: block;
      height: 46px;
      color: $base-blue;
      text-decoration: none;
    }
  }
  .fb_show {
    .facebook {
        width: 30px;
        height: 30px;
        background-image: url("img/facebook_blue.svg");
        background-repeat: no-repeat;
        background-position: center center;
    }
  }
}
