.footer {
  font-family: $font-family;
  .nav--bottom {
    ul {

    }
    li {
      display: inline-block;
      margin-right: 15px;
      &:after {
        content: '-';
        display: inline-block;
        margin-left: 15px;
        color: $light-grey;
      }
      &:last-of-type {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
    }
    a {
      text-decoration: none;
      color: $lightFontColor;
      font-size: 14px;
      color: $light-grey;
    }
  }
}