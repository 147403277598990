.home {
  background-color: $base-blue;
  article {
    background-color: $base-blue;
    //float: left;
    text-align: center;

    color: $lightFontColor;
    h1, h2, h3, h4, h5, h6, p:first-of-type {
      color: $lightFontColor;
    }

    &:before, &:after {
      background-color: $base-blue;
    }
    .button {
      border-color: $lightFontColor;
      color: $lightFontColor;
      margin-bottom: 20px;

      &:after {
        background-image: url("img/button_arrow_white.svg");
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        &:hover {
          &:after {
            background-image: url("img/button_arrow.svg");
          }
        }
      }

      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(6);
        &:nth-child(odd) {
          @include span(6 last);
        }
        &:hover {
          background-color: $lightFontColor;
          color: $base-blue;
        }
      }
    }
    .gewerkt--met {
      @include margin-top(2);
      @include span(4);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(12);
      }
    }
  }
}


//@include rhythm-height(1); //1 stands for how many lines
//@include margin-top(1); //1 stands for how many lines
//@include padding-top(1); //1 stands for how many lines
//@include margin-bottom(1); //1 stands for how many lines
//@include padding-bottom(1); //1 stands for how many lines
//@include leading-border($property:margin);
