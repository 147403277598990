.header__main__nav {
  font-family: $font-family;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    padding-right: 20px;
  }
  ul {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      //margin-top: 54px;
      margin-top: 30px;
    }
    li {
      height: 36px;
    }
    li ul.submenu {
      position: relative;
      margin-top: 0;
      height: 36px;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: none;
        width: 100vw;
        position: absolute;
        top: 36px;
        right: 0;
      }
      li {
        padding-left: 0;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          display: inline-block;
        }
      }

    }
    li {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: inline-block;
        margin-left: 20px;
        &:hover {
          ul.submenu {
            display: block;
          }
        }
      }
    }
    a {
      display: block;
      height: 100%;
      text-decoration: none;
      color: $baseHeaderMainFontColor;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        color: transparentize(white, 0.20);
        &:hover {
          color: white;
        }
      }
      &.facebook {
        width: 30px;
        height: 30px;
        background-image: url("img/facebook.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-repeat: no-repeat;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          margin-top: 12px;
        }
      }
    }
  }
}

.has-sub {
  &:hover {
    ul {
      display: block;
    }
  }
}



.header__main__nav {
  li {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: inline-block;
      margin-left: 20px;
    }
  }
  a {
    text-decoration: none;
    color: $baseHeaderMainFontColor;
  }
}

//
//.submenu {
//  //display: none;
//  position: relative;
//  margin-top: 0;
//
//  @include susy-breakpoint($breakpoint-large, $susy-large) {
//    width: 100vw;
//    position: absolute;
//    top: 38px;
//    right: 0;
//  }
//  li {
//    padding-left: 0;
//    @include susy-breakpoint($breakpoint-large, $susy-large) {
//      display: inline-block;
//    }
//  }
//}