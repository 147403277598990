input {
    border:none;
    border-radius: 4px;
    font-size: 18px;
    padding: 10px !important;
    &:focus {
        outline: none;
        //border: 1px solid palette(purple, light);
    }
}

.ginput_container_radio {
    input {
        margin: 0 0 0 1px;
    }
}

.input--text, .input--text:valid {
    @include rem('font-size', ms(-1));
    @include rhythm-height(1);
    display: block;
    margin: 0;
    //border: 1px solid palette(grey);
    border: none;
    border-radius: 4px;
    padding: 0 10px;
}

.input--error, .input--text:invalid {
    border: 1px solid red;
}

article {
    input {
        background-color: $form_input_bg;
    }
}
