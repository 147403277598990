article {
    a {
        text-decoration: underline;
        color: $base-blue;
        font-weight: normal;
    }
}
a:hover {

}

a:visited {

}

a:active {

}

a:focus {

}
