.header__top {
  display: none;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    display: block;
    //height: 80px;
    height: 70px;
    overflow: hidden;
    background-color: $baseHeaderTopBGcolor;
  }
  &__logo {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(7);
      height: 80px;
      font-size: 12px;
      color: #636363;
      overflow: hidden;
      padding-left: 20px;
      span {
        display: block;
        float: left;
        margin: 25px 16px 0 0;
      }
      img {
        margin-top: 15px;
      }
    }
  }
  &__nav {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(5 at 8);
      padding-right: 20px;
    }
  }
}