.circle {
  background-color: $base-green;
  width: 240px;
  height: 240px;
  color: $lightFontColor;
  border-radius: 50%;
  padding: 53px 30px 30px 30px;
  text-align: center;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  &__title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  &__number {
    font-size:64px ;
  }
}