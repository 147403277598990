.conversion-form {
  font-family: $font-family;
  .wrapper {
    padding: 0 20px;
  }
  .title {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      top: 220px;
    }
    h1 {
      font-weight: bold;
      font-size: 24px;
      text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.77);
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include adjust-font-size-to(48px);
      }
    }
  }
  .bg {
    background-color: transparentize($base-blue, 0.2);
    position: absolute;
    bottom:0;
    left: 0;
    right: 0;
    text-align: center;
    &:before, &:after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 20px;
      top: -20px;
      background-color: transparentize($base-blue, 0.2);
    }
    &:before {
      right: 50%;
      transform: skewX(45deg);
      margin-right: 10px;
    }
    &:after {
      left: 50%;
      transform: skewX(-45deg);
      margin-left: 10px;
    }
  }
  .text {
    color: $lightFontColor;
    font-weight: bold;
    p {
      font-size: 1.1rem !important;
      line-height: 1.7rem !important;
    }
  }
  .form {
    @include span(4);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(8);
      @include pre(2);
    }

    .gform_body {
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        display: inline-block;
        width: 50%;
        padding: 0;
        input {
          width: 100%;
        }
        &:nth-of-type(1) {
          width: 100%;
          display: block;
        }
        &:nth-of-type(2) {
          padding-right: 10px;
        }
        &:nth-of-type(3) {
          padding-left: 10px;
        }

        @include susy-breakpoint($breakpoint-large, $susy-large) {
          display: inline-block;
          &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
            padding: 0;
            display: inline-block;
          }
          &:nth-of-type(1) {
            width: 60%;
          }
          &:nth-of-type(2) {
            width: 20%;
            padding-left: 20px;
          }
          &:nth-of-type(3) {
            width: 20%;
            padding-left: 20px;
          }
        }

      }
    }

    li {
      display: inline-block;
      &:before {
        content: '';
      }
      label {
        display: none;
      }
    }
    .button {
      width: 100%;
      //@include susy-breakpoint($breakpoint-large, $susy-large) {
      //  width: 450px;
      //}
    }
  }
  .bel-nu {
    color: $lightFontColor;
    float: left;
    width: 100%;
    font-weight: bold;
    @include margin-top(1);
    @include margin-bottom(1);
    p {
      margin: 0;
      padding: 0;
      font-size: 1.1rem !important;
      line-height: 1.7rem !important;
    }
  }
}

#gform_wrapper_1 {
  margin-top: 0;
  margin-bottom: 0;
  .gform_footer.top_label {
    margin: 0;
    padding-bottom: 0;
  }
}