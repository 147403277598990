/* external css: flickity.css */

.gallery:after {
  content: 'flickity';
  display: none; /* hide :after */
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    content: '';
    display: block;
  }
}

.gallery-cell {
  width: 100%;
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  //content: counter(gallery-cell);
}