html {
    box-sizing: border-box;
    font-family: $base-font;
    // better font rendering
    -webkit-font-smoothing: antialiased;
    //noinspection CssUnknownProperty
    -moz-osx-font-smoothing: grayscale;
    color: $color-text-default;
}

*, *:before, *:after {
    box-sizing: inherit;
}

img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }
img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
.alignright { float: right; }
.alignleft { float: left; }
.aligncenter { display: block; margin-left: auto; margin-right: auto; }