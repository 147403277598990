.form__uitleg__wrap {
    display: none;
    overflow-y: scroll;
    color: #0099ff;
    &.active {
        display: block;
        position: fixed;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($dark-grey, 0.05);
        z-index: 10;
    }
    .form__uitleg {
        display: block;
        position: absolute;
        top: 40px;
        left: 10%;
        right: 10%;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: 10px;
        padding: 20px 30px;
        z-index: 20;
        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            background-image: url("img/close.svg");
            background-size: contain;
            width: 25px;
            height: 25px;
            cursor: pointer;
        }
        li {
            margin-bottom: 18px;
        }
    }
    .title {
        font-size: 1em;
        font-weight: 700;
        font-family: "Bitter", serif;
        padding-top: 20px;
    }
    .exampleText {
        clear: both;
    }
    p {
        font-size: .875em;
        margin: 0 !important;
        padding: 20px 0 0 0 !important;
    }

    .boordiameter_example .exampleImage, .spouw_example .exampleImage {
        float: left;
        width: 30%;
        font-size: 0.8em;
        line-height: 1.5;
        color: #636363;
        font-weight: bold;
        img {
            width: 100%;
            padding: 20px 20px 15px 0;
            height: auto;
        }
    }
}