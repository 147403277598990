.header__top__nav {
  font-family: $font-family;
  ul {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      text-align: right;
      margin-top: 20px;
    }
  }
  li {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: inline-block;
      margin-left: 20px;
    }
  }
  a {
    text-decoration: none;
    color: #0099FF;
    font-size: $base-font-size;
    font-weight: bold;
    &:hover {
    }
  }
}