#static-floor-table {
    ul {
        padding-left: 0;
    }

    .titel {
        padding-left: 0;

        label {
            margin-top: 0 !important;
            margin-left: 0 !important;
        }
    }

    .icons {
        span {
            width: 18.75%;
            @include susy-breakpoint($breakpoint-large, $susy-large) {
                width: 24%;
            }

            // Iphone 5 portrait
            @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
                width: 18.75%;
            }

            // Iphone 5 landscape
            @media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
                width: 19.4%;
            }

            // Iphone 6 portrait
            @media only screen and (min-device-width : 375px) and (max-device-width : 667px) {
                width: 19%;
            }

            // Iphone 6 landscape
            @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
                width: 19.5%;
            }

            // Ipad portrait
            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
                width: 19.5%;
            }
        }
    }

    .icon-info {
        li {
            color: #0099FF !important;

            span.icon {
                height: 20px !important;
            }
        }
    }

    .blue-text, .gsection_description {
        color: #0099FF !important;
    }
}