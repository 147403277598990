.breadcrumbs {
  font-size: 14px;
  margin-top: 20px;
  a {
    text-decoration: none;
    color: $breadcrumb-link-color;
    &:hover {
      color: $base-blue;
    }
  }

}