.header__main {
  background-color: transparentize($base-blue, 0.2);
  height: 60px;
  //overflow: hidden;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    height: 115px;
    &.hoger {
      height: 156px;
    }
  }
  &__logo {
    @include span(4);
    max-width: 225px;
    background-image: url("img/wujw_logo_mob.svg");
    background-repeat: no-repeat;
    background-position: 14px 14px;
    height: 60px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(4);
      height: 115px;
      background-image: url("img/wujw_logo.svg");
      background-position: 14px 14px;
      background-size: 135px 83px;
    }
  }
  &__nav {
    //@include span(1 at 4);
    display: none;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(9 at 4);
      display: block;
      text-align: right;
    }
  }

  .mob__toggle {
    //@include span(1 at 4);
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    //float: right;
    background-color: white;
    height: 60px;
    width: 115px;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      top:17px;
      right: 65px;
      text-decoration: none;
      color: $base-blue;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
    }
    .toggle__icon {
      position: absolute;
      top:17px;
      right: 20px;
      background-image: url("img/menu_open.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 31px;
      height: 25px;
      &.active {
        background-image: url("img/close.svg");
      }
    }

    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: none;
    }
  }

  &__mob__nav {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: none;
    }
  }

  &.active {
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    z-index: 10;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      position: absolute;
    }
  }

}