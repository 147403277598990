article {
    position: relative;
    padding: 40px 0 60px 0;
    overflow: hidden;
    margin-top: -40px;
    .inner {
        padding: 0 20px;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include span(10);
            @include pre(1);
        }
    }

    color: $dark-grey;
    h1 {
        color: $base-blue;
        @include adjust-font-size-to(24px);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            @include adjust-font-size-to(36px);
        }
        padding-top: 20px;
    }
    h2, h3, h4, h5, h6, p:first-of-type {
        color: $base-blue;
        @include adjust-font-size-to($base-font-size);
    }
    h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }

    &:before, &:after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 20px;
        top: 21px;
        background-color: white;
    }
    &:before {
        right: 50%;
        transform: skewX(45deg);
        margin-right: 10px;
    }
    &:after {
        left: 50%;
        transform: skewX(-45deg);
        margin-left: 10px;
    }

    h1 + p {
        color: $base-blue;
    }
    p.breadcrumbs {
        color: $base-blue;
    }

}
