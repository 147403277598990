.footer {
  font-family: $font-family;
  .nav--top {
    @include margin-trailer(1);
    li {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        display: inline-block;
        margin-right: 15px;
        &:after {
          content: '|';
          display: inline-block;
          margin-left: 15px;
        }
        &:last-of-type {
          margin-right: 0;
          &:after {
            display: none;
          }
        }

      }
    }
    a {
      text-decoration: none;
      color: $lightFontColor;
      font-size: 18px;
    }
    .submenu {
      display: none;
    }
  }
}


