h1, h2, h3, h4, h5, h6 {
    @extend %tw-semi-bold;
    font-family: $font-family;
}

h1 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-xxlarge;
}

h2 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-xlarge;
}

h3 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-large;
}

h4 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %t-medium;
}

h5, h6 {
    //noinspection SassScssUnresolvedPlaceholderSelector
    @extend %base;
}
