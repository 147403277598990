// Navigation
nav {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        padding: 0;
        margin: 0;
        display: block;
        &:before {
            content: '';
        }
    }
    a {
        font-size: 18px;
    }
}

.nav__item {
    @include set-font-size-variant('small');
    width: 100%;
    a {
        color: palette(white);
    }
}