.cat {

  &__naam {
    position: relative;
    font-size: 24px;
    color: $base-blue;
    border-bottom: 1px solid $mid-grey;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 24px;
    cursor: pointer;
    &:after {
      content: ' ';
      position: absolute;
      top: 12px;
      right: 0;
      width: 18px;
      height: 16px;
      //background-image: url("img/arrow-blue.png");
      background-image: url("img/arrow.svg");
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotate(180deg);
    }
    &.active {
      &:after {
        transform: rotate(0deg);
      }
    }
  }

  .vragen {
    display: none;
    &.active {
      display: block;
    }
  }

  .vraag {
    &__title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
      cursor: pointer;
    }

    &__text {
      display: none;
      p {
        font-size: 1rem;
        line-height: 22px;
        color: $dark-grey;
        margin: 0 0 24px 0;
      }
      &.active {
        display: block;
      }
    }

  }

  p:first-of-type {
    color: $dark-grey;
  }

}