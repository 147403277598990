////////////////////////////////
//  Font sizes map
//
//  Specify multiple font sizes for breakpoints,
//  Mobile font-size & line height should be defined by $base-font-size & $base-line-height.
//  Required values:
//    font-size: font-size in px
//    line-height: line-height in px
//    breakpoint: breakpoint valid unit (em/px/etc)
//  Optional:
//    round-to-half-line: true/false
////////////////////////////////

//$base-font-sizes: (
//        large: (
//                font-size: 24px,
//                line-height: 36px,
//                breakpoint: $breakpoint-desktop,
//                round-to-half-line: true
//        )
//);

$base-font-sizes: (
        large: (
                font-size: 18px,
                line-height: 28px,
                breakpoint: $breakpoint-large,
                round-to-half-line: false
        )
);

////////////////////////////////
//  Font size variants
//  These will eventually generate %t-name placeholders
//  Possible values:
//    leader lines before element (margin)
//    trailer lines after element (margin)
//    font-size font size in px

////////////////////////////////

$base-variants: (
        small: (
                leader: 0,
                'font-size': ms(-1),
        ),
        base: (
                leader: 0,
                'font-size': ms(0),
        ),
        medium: (
                leader: 0,
                'font-size': ms(1),
        ),
        large: (
                leader: 0,
                'font-size': ms(2),
        ),
        xlarge: (
                leader: 0,
                trailer:0,
                'font-size': ms(3),
        ),
        xxlarge: (
                leader: 0,
                'font-size': ms(4),
        ),
        xxxlarge: (
                leader: 0,
                'font-size': ms(5),
        )
);

////////////////////////////////
// Generate base setup for fonts
////////////////////////////////
@import "typography-base-rhythm";

////////////////////////////////
//  Type weights
////////////////////////////////

%tw-extrabold {
  font-weight: 900;
}

%tw-bold {
  font-weight: 700;
}

%tw-semi-bold {
  font-weight: 600;
}

%tw-regular {
  font-weight: 400;
}

%tw-light {
  font-weight: 300;
}

%tw-ultralight {
  font-weight: 100;
}

body {
  padding: 0;
  margin: 0;
}

////////////////////////////////
//  Default styles - edit if needed
////////////////////////////////

p, ol, ul, blockquote {
  //noinspection SassScssUnresolvedPlaceholderSelector
  @extend %t-base;
}

//ul, ol {
//    margin-left: 0;
//    padding-left: 0;
//    color: palette(grey);
//    span {
//        color: $color-text-default;
//    }
//}
//
//ul li {
//    margin-left: 1em;
//}
//
//ol li {
//    margin-left: 1.5em;
//}
//
//a[href^=tel] {
//    color: inherit;
//    text-decoration: none;
//}
