/* =============================================
   Grid setup. Also see: http://susy.oddbird.net/demos/grid-types/
   ============================================= */
$susy-debug: (
    debug:(
        image: show-columns,
        color:rgba(0, 128, 0, .2),
        output: background,
    )
);

$susy: (
    columns: 4,
    gutters: 1/4,
    math: fluid,
    output: float,
    box-sizing: border-box,
);

$susy-large: (
    columns: 12,
    gutters: 24px/98px,
    math: fluid,
    output: float,
    // note the 18px -> this is the base font-size for the large breakpoint. @see $base-font-sizes in typography.scss
    column-width: (98px / 24px) *1em,
    box-sizing: border-box,
);

@if ($debug) {
    $susy: map-merge($susy, $susy-debug);
    $susy-large: map-merge($susy-large, $susy-debug);
}

// Grid container setup
.wrapper {
    // make it the container of the grid
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include container();
    }
}
