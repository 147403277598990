.gf_progressbar_wrapper {
  display: none;
}

.ginput_container {
  margin-top: 0 !important;
}

.ginput_container_number {
  margin-bottom: 15px;
}

.gform_wrapper .gsection, .gform_wrapper .gform_page_footer {
  border: none;
}

#gform_wrapper_2 {
  .gform_heading {
    display: none;
  }
}

.gform_wrapper .gfield_checkbox li label, .gform_wrapper .gfield_radio li label {
  margin: 4px 0 0 24px;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    margin: 4px 0 0 24px;
  }
}

//.jumbo form {
//  .gform_body {
//    @include susy-breakpoint($breakpoint-large, $susy-large) {
//      @include span(8);
//    }
//    @include susy-breakpoint($breakpoint-small, $susy-small) {
//      @include span(12);
//    }
//    margin-right: 0;
//    float: left;
//  }
//  .gform_footer {
//    @include susy-breakpoint($breakpoint-large, $susy-large) {
//      @include span(4);
//      padding: 0;
//    }
//    @include susy-breakpoint($breakpoint-small, $susy-small) {
//      @include span(12);
//    }
//    float: right;
//    clear: none;
//  }
//}

.gform_wrapper  {
  color: $base-blue;
  .gsection {
    margin-bottom: 0;
  }
  .gform_footer {
    padding: 0 !important;
  }
  .gsection_description {
    p {
      margin-top: 0;
    }
  }
  ul li.gfield {
    padding-left: 0;
    input {
      width: 100%;
    }
    &.small {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(2);
        //@include pre(1);
        clear: none;
      }
      &.vierkante-meter {
        .gfield_description {
          top: 7px;
          right: 0;
          margin: 0;
          width: 20%;
          float: right;
        }
        input {
          width: 75%;
        }
      }
    }
    &.medium {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(4);
        clear: none;
      }
    }
    &.mediumLarge {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(6);
        clear: none;
      }
    }
    &.large {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(12);
        clear: none;
      }
    }

    &.gform__hide-title .gfield_label {
      display: none;
    }

    p {
      color: $base-blue;
    }
  }
  #field_2_5 .ginput_container_text, #field_2_6 .ginput_container_text, #field_2_16 .ginput_container_text {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      margin-top: 10px !important;
    }
  }
  #field_2_10, #field_2_86 {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(last 6);
      clear: none;
    }
  }
  #field_2_16 {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(6);
      clear: none;
    }
  }
  #field_2_90 {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(last 4);
      clear: none;
    }
  }
  #field_2_9 {
    clear: both;
  }
  #input_2_28, #field_2_30, #field_2_34 {
    li {
      margin-top: 11px;
    }
  }
  #input_3_1_3_container {
    label {
      display: none;
    }
  }
  // checkboxes on one row
  #field_2_40, #field_2_41, #field_2_43, #field_2_44, #field_2_23, #field_2_68 {
    .gfield_checkbox li {
      display: inline-block;
      margin-right: 16px;
    }
  }

  #field_2_45 {
    margin-bottom: 20px;
  }

  .top_label .gfield_label {
    padding-top: 20px;
  }

  h2.gsection_title {
    padding-top: 20px !important;
  }

  .gsection {
    margin: 0 !important;
  }

  .gsection_description {
    padding: 0px 0px 12px 0 !important;
  }

  .gsection_description p {
    margin: 0 !important;
  }

  .gfield_html.gfield_no_follows_desc {
    margin-top: 20px !important;
  }

  // Top error message
  div.validation_error {
    border: none !important;
    margin-bottom: 0 !important;
  }

  // Label validation
  li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-top: 8px !important;
  }

  // Message validation
  li.gfield.gfield_error.gfield_contains_required div.validation_message {
    max-width: 97.5%;
    font-size: 10px !important;
    background-color: transparent !important;
    border-top: none !important;
    border-bottom: none !important;
    color: #790000;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-top: none !important;
    border-bottom: none !important;
    clear: both;
    width: 97.5%;
    padding: 10px 0 0 0 !important;
  }

  .spouwmuurisolatie_error, .bodemisolatie_error {
    width: 0px;
    height: 0px;
  }

  .spouwmuurisolatie_validation, .bodemisolatie_validation {
    color: #790000;
    font-weight: 700;
    letter-spacing: normal;
    font-size: 10px;
    display: none;
  }

  .gfield_description.validation_message {
    width: 100% !important;
    float: left !important;
  }

  li.gfield.gfield_error.gfield_contains_required div.gfield_description, li.gfield.gfield_error.gfield_contains_required div.ginput_container, li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label, li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-left: 0px !important;
  }

  .ginput_complex label {
    margin-left: 0px !important;
  }

  // Field validation
  li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: transparent !important;
    border: none !important;
    margin-top: 0 !important;
  }

  li.gfield.gfield_error {
    background-color: transparent !important;
    border: none !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  li.gfield.gfield_error input{
    background-color: #FFDFE0 !important;
  }
}


.gfield_checkbox {
  li {
    input {
      height: 16px;
      margin-bottom: 0;
    }
  }
  &#input_2_21 {
    li {
      display: inline-block;
      margin-right: 16px;
    }
  }
}

.wujw__form {
  &__special-header {
    padding-left: 0;
    overflow: hidden !important;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      //@include span(5);
      //@include pre(1);
      width: 48%;
    }
    &.spouwmuurisolatie {
      .icons {
        span {
          &.afmeting {
            cursor: pointer;
            transform: rotate(90deg);
          }
        }
      }
    }
    .titel {
      height: 60px;
      padding-top: 17px;
      //margin-left: -16px;
      //@include susy-breakpoint($breakpoint-large, $susy-large) {
      //  margin-left: -24px;
      //}
      h2 {
        font-size: 18px;
        line-height: 24px;
        color: $dark-grey;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          font-size: 15px;
        }
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        width: 40%;
        float: left;
      }
      li {
        padding-left: 0;
      }
    }
    .icons {
      // icon_header.png
      text-align: right;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        width: 60%;
        float: right;
      }
      span {
        color: $dark-grey;
        position: relative;
        display: inline-block;
        width: 20%;
        //float: left;
        height: 60px;
        line-height: 60px;
        text-align: center;
        vertical-align: middle;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          width: 25%;
        }
        &.blue {
          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparentize($base-blue, 0.95);
          }
        }
        &.afmeting, &.vocht, &.besparing, &.kosten {
          background-repeat: no-repeat;
          background-position: center center;
          cursor: pointer;
        }
        &.afmeting {
          background-image: url("img/icon_afmeting.png");
        } 
        &.vocht {
          background-image: url("img/icon_vocht.png");
        }
        &.besparing {
          background-image: url("img/icon_besparing.png");
        }
        &.kosten {
          background-image: url("img/icon_kosten.png");
        }
      }
    }
  }
  &__special-entry {
    display: block;
    position: relative;
    background-color: transparentize($base-blue, 0.90);
    overflow: hidden !important;
    margin-bottom: 1px;
    font-size: 18px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      //@include span(5);
      //@include pre(1);
      width: 48%;
    }

    .titel {
      color: $base-blue;
      font-weight: bold;
      height: 28px;
      margin-top: 17px;
      padding-left: 20px;
      font-size: 18px;
      label {
        font-size: 18px;
        margin: 0 0 0 24px !important;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          font-size: 13px;
          margin: 4px 0 0 24px !important;
        }
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        width: 40%;
        float: left;
        font-size: 13px;

      }
    }
    .info {
      text-align: right;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        width: 60%;
        float: right;
      }
      span {
        color: $dark-grey;
        position: relative;
        display: inline-block;
        width: 20%;
        //float: left;
        height: 30px;
        line-height: 30px;
        text-align: center;
        vertical-align: middle;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          height: 60px;
          line-height: 60px;
          width: 25%;
          font-size: 14px;
        }
        &.blue {
          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: -200%;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparentize($base-blue, 0.90);
          }
        }
      }
    }
  }
}

.field_description_below li {
  font-size: .875em;
}


.form_kop h2.gsection_title {
  line-height: 1.3 !important;
  font-size: 0.8125rem !important;
  font-family: "Source Sans Pro", sans-serif !important;
  margin: .625em 0 .5em !important;
  font-weight: 700;
}

.gfield {
  &.mob {
    display: block;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: none;
    }
  }
}

.icon-info {
  position: relative;
  display: block;
  list-style: none;
  margin-bottom: 40px !important;
  overflow: hidden;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    width: 48%;
  }
  &.mob {
    display: block;
    margin-top: 30px !important;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: none;
      margin-top: 0 !important;
    }
  }
  &.large {
    display: none;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: block;
      position: absolute;
      left: 55%;
    }
  }
  &.lightbox {

  }
  li {
    display: block;
    //float: left;
    //width: 50%;
    list-style: none !important;
    padding-left: 51px !important;
    font-size: .875em;
    span {
      font-size: 18px;
    }
  }
}

.icon {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-right: 10px;
  &.hoogte, &.breedte {
    background-image: url("img/icon_afmeting.png");
  }
  &.breedte {
    transform: rotate(90deg);
  }
  &.energie {
    background-image: url("img/icon_besparing.png");
  }
  &.vocht {
    background-image: url("img/icon_vocht.png");
  }
  &.kosten {
    background-image: url("img/icon_kosten.png");
  }
}

.mandatoryField {
  font-size: 13px !important;
  padding-top: 10px;
  color: #0099ff;
}

.confirmTitle {
  font-size: 1.3em;
  font-weight: 700;
  padding-top: 20px;
}

.bodemisolatie_uitleg {
  li {
    list-style-type: none !important;
  }
  display: none;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    display: block;
  }
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul li, body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul li {
  list-style-type: none !important;
}