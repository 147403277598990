.conversion-deelnemers {
  position: relative;
  background-color: $dakr-blue;
  color: $lightFontColor;
  overflow: hidden;
  height: auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 20px;
  font-weight: bold;
  vertical-align: middle;
  padding: 10px;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    height: 98px;
    line-height: 50px;
    padding: 0;
  }
  .highlighted-number {
    font-size: 34px;
    color: #636363;
    background-color: white;
    padding: 2px 14px 2px 14px;
    border-radius: 4px;
    margin: 0 10px;
    line-height: 39px;
  }
  span {
    display: inline-block;
    position: relative;
    line-height: 37px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      line-height: 98px;
    }
  }
}

