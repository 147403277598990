.sfeer {
  position: relative;
  display: block;
  //min-height: 600px;
  //@include susy-breakpoint($breakpoint-large, $susy-large) {}
  &.foto {
    @include flexEmbed(16, 5);
    background-size: cover;
    background-position: 50% 0%;
  }
  &.laag {
    background: none;
    height: 79px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
     height: 155px;
    }
  }
  img {
    display: none;
    width: 100%;
    height: auto;
  }
  .header__main {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    text-align: center;
    &.active {
      position: fixed;
      top:0;
      right: 0;
      left: 0;
      z-index: 10;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        position: absolute;
      }
    }
  }
}