footer {
  background-color: $dark-grey;
  color: $lightFontColor;
  text-align: center;
  padding: 40px;
  font-size: 18px;

  .inner {
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(10);
      @include pre(1);
    }
  }

  .samen--met {
    color: $light-grey;
  }
  .gewerkt--met {
    color: $light-grey;
  }

}