.conversion-block {
  padding: 30px;
  @include container();
  text-align: center;
  .title {
    color: $base-pink;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .button {
    border-color: $base-pink;
    color: $base-pink;
    padding-left: 60px;
    padding-right: 60px;
    max-width: 100%;

    &:after {
      background-image: url("img/button_arrow_purple.svg");
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      &:hover {
        background-color: $base-pink;
        color: $lightFontColor;
        &:after {
          background-image: url("img/button_arrow_white.svg");
        }
      }
    }
  }
  &.actie {
    padding: 30px 0;
    .button {
      width: 100%;
      border-color: $base-blue;
      color: $base-blue;
      &:after {
        background-image: url("img/button_arrow.svg");
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        &:hover {
          background-color: $base-blue;
          color: $lightFontColor;
          &:after {
            background-image: url("img/button_arrow_white.svg");
          }
        }
      }
    }
  }
}