::-moz-selection {
    background: palette(purple, light);
    text-shadow: none;
    color: palette(white);
}

::selection {
    background: palette(purple, light);
    color: palette(white);
    text-shadow: none;
}
