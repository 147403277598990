.cookie-notice-container {
  padding: $base-line-height;
  #cn-notice-text {
    display: block;
    clear: both;
    @include rem('font-size', ms(0));
    @include rem('line-height', ms(0));
    padding-bottom: $base-line-height;
  }

  .button {
    @include rem('font-size', ms(0));
    @include rem('line-height', ms(0));
    height: auto;
    border-color: $lightFontColor;
    color: $lightFontColor;
    padding: $base-line-height*0.5 $base-line-height !important;
    margin-bottom: 20px;
    margin-left:0;
    &:after {
      background-image: url("img/button_arrow_white.svg");
    }

    &:hover {
      background-color: $lightFontColor;
      color: $base-blue;
      &:after {
        background-image: url("img/button_arrow.svg");
      }
    }
  }
}