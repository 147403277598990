label {
    @include rem('font-size', ms(-1));
    display: block;
    width: 100%;
}

#gform_1 {
    // lelijke oplossing... checken of het anders kan
    ul li label.gfield_label {
        display: none !important;
    }
}

.gsection_title {
    margin-top: 20px;
}
