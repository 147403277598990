.button, input[type="button"], input[type="submit"]  {
    &:after {
        //background-image: url("img/button_arrow.svg");
        content: close-quote;
        display: inline-block;
        width: 10px;
        height: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 12px;
        background-size: contain;
        margin-bottom: -2px;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            width: 10px;
            height: 16px;
        }
    }
}

.button {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    @include rem('font-size', ms(-1));
    font-size: 14px;
    height: 50px;
    border-radius: 25px;
    padding: 9px 10px 0 10px;
    color: $base-blue;
    text-decoration: none;
    border: 1px solid $base-blue;
    outline: none;
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
        background-image: url("img/button_arrow.svg");
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
        width: auto;
        padding: 9px 60px 0 60px !important;
        font-size: 20px;
        &:hover {
            background-color: $base-blue;
            color: $lightFontColor;
            &:after {
                background-image: url("img/button_arrow_white.svg");
            }
        }
    }
    &[type="submit"], &[type="button"], &.purple, &.blue {
        border: none;
        padding: 0px 10px 0 10px !important;
        @include susy-breakpoint($breakpoint-large, $susy-large) {
            padding: 0px 60px 0 60px !important;
        }
    }
    &[type="submit"] {
        background-color: $button-bg;
        color: $lightFontColor;
    }
    &[type="button"] {
        background-color: $base-blue;
        color: $lightFontColor;
        &.gform_previous_button {
            background-color: $lightFontColor;
        }
    }
    &.purple {
        color: white;
        background-color: $base-pink;
        &:after {
            background-image: url("img/button_arrow_white.svg");
        }
    }
    &.blue {
        color: white;
        background-color: $base-blue;
        &:after {
            background-image: url("img/button_arrow_white.svg");
        }
    }
}
.gform_page_footer.top_label {
    .button {
        &[type="submit"] {
            background-color: $base-blue;
        }
    }
}

.button.purple.facebook_campaign {
    width: 100%;
}
