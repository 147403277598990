///////////////////////////////////////////////////////////////////////
// At a minimum every palette defines a base colour,
// and then optionally adds tones use the following naming pattern:
//
// - x-dark
// - dark *
// - mid-dark
// - base (default)
// - mid-light
// - light *
// - x-light
//
//
// *If we have fewer than seven tones, we’ll define light and dark first to allow
// for adding intermediate tones at a later date.
//
// Use the palette function
//
// @link http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
//

$color-text-default: #333;
$palettes: (
    purple: (
        dark: rgb(40, 38, 65),
        base: rgb(42, 40, 80),
        light: rgb(51, 46, 140),
        x-light: rgb(107, 107, 184),
    ),
    grey: (
        dark: darken(rgb(128, 128, 128), 10%),
        base: rgb(128, 128, 128),
        light: lighten(rgb(128, 128, 128), 10%),
    ),
    white: (
        base: #fff
    ),
    blue: (
        base: #3372df
    )
);

///////////////////////////////////
// Access the colors in the $palettes map
// Example: color: palette(purple); color: palette(purple, light);
//
@function palette($palette, $tone: 'base') {
    @return map-get(map-get($palettes, $palette), $tone);
}
