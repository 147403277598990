// Debug?
$debug: false !default;

////////////////////////////////
//  Font Settings / vertical rhythm
////////////////////////////////

// All font-sizes in rem
$rhythm-unit: "rem";
// ..with px fallback for IE8
$rem-with-px-fallback: true;

$base-font-size: 16px;
// base font size used in design
$base-font-size-for-calculations: 16px;
$base-font-leading: 1;
$base-line-height: 26px;
$round-to-nearest-half-line: false;
$ms-base: $base-font-size;
$ms-ratio: $major-third;

////////////////////////////////
//  Breakpoints
/////////////////////////////// /
$breakpoint-small: 30em;
$breakpoint-large: 60em; //60 x 16

// dpi breakpoints
$breakpoint-hidpi: min-resolution 1.5dppx;

////////////////////////////////
//  Font family
////////////////////////////////

$font-family: 'Bitter', serif;
$base-font: 'Source Sans Pro', sans-serif;

////////////////////////////////
//  Nav Colors
////////////////////////////////
$baseHeaderTopBGcolor: #fff;


$baseHeaderMainFontColor: #fff;
$baseHeaderMainFontColorMob: #fff;

$lightFontColor: #fff;

$base-blue: rgba(0,153,255,1);
$dakr-blue: rgba(0,102,204,1);
$button-bg: rgba(226,28,159,1);

$base-green: rgba(141,198,63,1);
$base-pink: rgba(226,28,159,1);

$dark-grey: rgba(99,99,99,1);
$mid-grey: rgba(216,216,216,1);
$light-grey: rgba(170,170,170,1);

$form_input_bg: rgba(242,242,242,1);

$breadcrumb-link-color: rgba(163,163,163,1);

$header__main__mob__nav--bg--color: #eee;