.testimonials {
  display: block;
  background-color: transparentize($base-blue, 0.85);

  .flickity-viewport {
    background-color: transparentize($base-blue, 0.85);
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      background-color: transparent;
    }
  }
  .wrapper {
    background-color: white;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      display: flex;
      flex-wrap: wrap; // allow wrapping items
    }
  }

  //niks
  &__item {
    padding-bottom: 40px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      height: auto;
      flex-grow: 1;
      flex-basis: 33%; // 50% for two in a row, 33% three in a row etc.
    }

    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(4 inside);
      //@include flexEmbed(4, 11);
    }
    &__inner {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        //position: absolute;
        //top: 0;
        //left: 0;
        //right: 0;
      }
    }
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      background-color: transparentize($base-blue, 0.85);
    }
    &:nth-of-type(2) {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        background-color: transparentize($base-blue, 0.95);
        @include span(4 inside);
      }
    }
    &:nth-of-type(3){
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(4 last inside);
      }
    }
    &__image {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 40px 20px;
      }
    }
    &__text {
      position: relative;
      padding: 0 100px;
      color: $base-blue;
      font-size: 18px;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        font-size: 20px;
      }
      &:before {
        display: block;
        position: absolute;
        //\201C
        content: ' ';
        width: 60px;
        height: 108px;
        background-image: url("img/quote_left.svg");
        background-repeat: no-repeat;
        top: -7px;
        left: 47px;
      }
      p {
        &:after {
          //\201D
          content: close-quote;
          display: inline-block;
          background-image: url("img/quote_right.svg");
          width: 60px;
          height: 35px;
          background-repeat: no-repeat;
          background-position: center center;
          margin-bottom: -17px;
        }
      }
    }
    &__name {
      padding: 0 100px 40px;
      color: $base-blue;
      font-size: 14px;
      @include susy-breakpoint($breakpoint-large, $susy-large) {

      }
    }

  }
}