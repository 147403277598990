.textbox {

  &__item {
    padding: 20px;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      @include span(6);
      padding: 50px;
      &:nth-of-type(2) {
        @include span(6 last);
      }

    }
    &--circle {
      padding: 50px;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(2);
      }
    }
    &.offset--2 {
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        @include span(6 at 2);
        @include pre(1);
      }
    }
    &__title {
      @include adjust-font-size-to(22px);
      @include margin-trailer(1);
      color: $base-blue;
      font-weight: bold;
      line-height: 28px;
    }
    &__text {
      font-size: 18px;
      color: $base-blue;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        font-size: 24px;
      }
    }
  }

  .textbox__2colors {
    position: relative;
    @include susy-breakpoint($breakpoint-large, $susy-large) {
      &:before, &:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
      &:before {
        left: 0;
        right: 50%;
        background-color: $base-blue;
      }
      &:after {
        right: 0;
        left: 50%;
        background-color: transparentize($base-blue, 0.90);
      }
    }

    .textbox__item {
      background-color: $base-blue;
      &:nth-of-type(2) {
        background-color: transparentize($base-blue, 0.90);
        @include susy-breakpoint($breakpoint-large, $susy-large) {
          background-color: transparent;
        }
      }
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        background-color: transparent;
      }
      &.blue {
        .textbox__item__title {
          color: $lightFontColor;
        }
        .textbox__item__text {
          color: $lightFontColor;
        }
        .button {
          border-color: $lightFontColor;
          color: $lightFontColor;

          &:after {
            background-image: url("img/button_arrow_white.svg");
          }
          @include susy-breakpoint($breakpoint-large, $susy-large) {
            &:hover {
              background-color: $lightFontColor;
              color: $base-blue;
              &:after {
                background-image: url("img/button_arrow.svg");
              }
            }
          }
        }
      }
    }

  }

}