/*
Theme Name: Default Wordpress
Author: Emotion kommunikatie buro
Author URI: http://emotion.nl
Description: Based on the default-site theme
Version: 0.1
Text Domain: default

Use it to make something cool, have fun, and share what you've learned with others.
*/
/* ==========================================================================
    Base file, all includes, defines & custom mixins should be added here
    ========================================================================= */
// Framework includes
@import "susy";
@import "breakpoint";
@import 'modular-scale';
@import "../../../../../../node_modules/vertical-rhythms-without-compass/vertical-rhythms";
// Vendor includes
@import "vendor/normalize";
// Utilities
@import "utilities/mixins";
@import "utilities/variables";
@import "utilities/palettes";
@import "utilities/grid";
@import "utilities/typography";
@import "utilities/carousel";
@import "utilities/carousel-options";
// Atoms
@import "atoms/html";
@import "atoms/body";
@import "atoms/buttons";
@import "atoms/headings";
@import "atoms/text-selection";
@import "atoms/links";
@import "atoms/lists";
@import "atoms/inputs";
@import "atoms/labels";
@import "atoms/circle";
// Molecules
@import "molecules/nav";
@import "molecules/header_top_nav";
@import "molecules/header-main-nav";
@import "molecules/header-mobile-nav";
@import "molecules/header-home";
@import "molecules/footer-nav-top";
@import "molecules/footer-nav-bottom";
@import "molecules/breadcrumbs";
@import "molecules/actie-button";
@import "molecules/_example";
// Organisms
@import "organisms/footer";
@import "organisms/header";
@import "organisms/article";
@import "organisms/article-home";
@import "organisms/testimonials";
@import "organisms/textbox";
@import "organisms/conversion-block";
@import "organisms/vragen";
@import "organisms/gform";
@import "organisms/static-table";
@import "organisms/header-main";
@import "organisms/header-top";
@import "organisms/jumbo";
@import "organisms/sfeer";
@import "organisms/conversion-form";
@import "organisms/conversion-deelnemers";
@import "organisms/cookie-notice-container";
// Templates
@import "templates/page";