.jumbo {
  position: relative;
  display: block;
  @include flexEmbed(16, 9);
  background-size: cover;
  background-position: center center;
  min-height: 600px;
  @include susy-breakpoint($breakpoint-large, $susy-large) {
    min-height:650px;
    max-height:650px;
  }
  img {
    display: none;
    width: 100%;
    height: auto;
  }
  .header__main {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    text-align: center;
    &.active {
      position: fixed;
      top:0;
      right: 0;
      left: 0;
      z-index: 10;
      @include susy-breakpoint($breakpoint-large, $susy-large) {
        position: absolute;
      }
    }
  }
}